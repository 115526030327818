<!--
 * @Author: your name
 * @Date: 2021-04-25 17:07:13
 * @LastEditTime: 2021-05-15 17:26:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\evaluation\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation msg="评价"></navigation>
    <div class="pclass">
      <div class="na">评价内容</div>
      <!-- <div class="pjtcla">
        <div @click="kjmeth('非常满意')">非常满意</div>
        <div @click="kjmeth('很好')">很好</div>
      </div> -->
      <div class="inmsg">
        <van-field
          v-model="message"
          rows="1"
          autosize
          type="textarea"
          placeholder="请输入留言"
        />
        <!-- <div class="clearmsg">清空</div> -->
      </div>
      <div class="tipc">您也可以选择下面的标签直接生成评价内容 <span @click.stop="toskill" class="jnclasses">技能评价</span></div>
      <div class="zymenu">
        <div
          @click="menumeth(0)"
          :class="menuindex == 0 ? 'seleclass' : 'seleclass2'"
        >
          工作表现
        </div>
        <div
          @click="menumeth(1)"
          :class="menuindex == 1 ? 'seleclass' : 'seleclass2'"
        >
          团队评价
        </div>
        
        <div
          @click="menumeth(3)"
          :class="menuindex == 3 ? 'seleclass' : 'seleclass2'"
        >
          重大贡献
        </div>
        <div
          @click="menumeth(2)"
          :class="menuindex == 2 ? 'seleclass' : 'seleclass2'"
        >
          推荐信
        </div>
      </div>
      <div v-if="menuindex == 0 && jinengarray != ''" class="xlall">
        <div
          v-for="(item, index) in jinengarray[0].list[0].list"
          v-bind:key="index"
          class="xl"
          @click="clickjin(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div v-if="menuindex == 1 && jinengarray != ''" class="xlall">
        <div
          v-for="(item, index) in jinengarray[1].list[0].list"
          v-bind:key="index"
          class="xl"
          @click="clickjin(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div v-if="menuindex == 2 && jinengarray != ''" class="xlall">
        <div
          v-for="(item, index) in jinengarray[2].list[0].list"
          v-bind:key="index"
          class="xl"
          @click="clickjin(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        v-if="menuindex == 3 && jinengarray != '' && jinengarray[3].list"
        class="xlall"
      >
        <div
          v-for="(item, index) in jinengarray[3].list[0].list"
          v-bind:key="index"
          class="xl"
          @click="clickjin(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <button @click="uppingjiatwo" id="bu">提交评价内容</button>
    <canvas width="100" height="100" id="ccc"></canvas>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuindex: 0,
      message: "",
      jinengarray: "",
    };
  },
  watch:{
    message(newmsg,oldmsg){
      if((newmsg+'').length>300){
        this.message = oldmsg;
      }
    }
  },
  created() {
    //获取标签技能
    this.getjineng();
  },
  mounted() {},
  methods: {
    toskill(){
      this.$router.push({
        path:"skilllist",
        query:{
          phone:this.$route.query.phone
        }
      })
    },
    uppingjiatwo() {
      if (this.$route.query.ishui) {
        //回评
        this.uphuimsg();
      } else {
        this.uppingjia();
      }
    },
    //回评
    uphuimsg() {
      if (!this.message) {
        this.$toast("请输入回评内容");
        return;
      }
      if ((this.message + "").length > 300) {
        this.$toast("内容过多");
        return;
      }
      this.$http
        .post("/firm/v1/profile/replyAdd", {
          reqType: "profile",
          evaluation_id: this.$route.query.id,
          content: this.message,
          label_type: 1,
          label_desc: "",
          comment_set: 1,
        })
        .then((res) => {
           
          this.$toast("回评成功");
          this.$router.go(-1);
        });
    },
    //提交评价
    uppingjia() {
      if (!this.message) {
        this.$toast("请输入评价内容");
        return;
      }
      if ((this.message + "").length > 300) {
        this.$toast("内容过多");
        return;
      }
      this.$http
        .post("/firm/v1/profile/evalution", {
          reqType: "profile",
          sid: atob(this.$route.query.sid),
          status: atob(this.$route.query.status),
          user_id: atob(this.$route.query.user_id),
          content: this.message,
        })
        .then((res) => {
           
          this.$toast("提交成功");
          this.$router.go(-1);
        });
    },
    //点击技能
    clickjin(msg) {
      this.message =
        this.message == ""
          ? this.message + msg.name
          : this.message + "，" + msg.name;
    },
    //快捷输入
    kjmeth(msg) {
      this.message =
        this.message == "" ? this.message + msg : this.message + "，" + msg;
    },
    menumeth(index) {
      this.menuindex = index;
    },
    getjineng() {
      this.$http
        .post("/firm/v1/profile/evalutionTag", {
          reqType: "profile",
        })
        .then((res) => {
           
          this.jinengarray = JSON.parse(res.data).data;
        });
    },
  },
};
</script>
<style scoped>
.jnclasses{
  margin-left: .3rem;
  color: #1A73E8;
}
#bu {
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  border: none;
  width: 90%;
  font-size: 0.3rem;
  line-height: 0.42rem;
  color: #ffffff;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 0.28rem 0;
}
.xlall {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 3rem;
  max-height: 3rem;
  overflow: auto;
}
.xl {
  padding: 0.2rem 0.3rem;
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #ff5f19;
  border: 2px solid #ff5f19;
  border-radius: 0.39rem;
  text-align: center;
  margin-right: 0.24rem;
  margin-bottom: 0.24rem;
}
.seleclass {
  font-weight: bold;
  color: #333333;
}
.seleclass::after {
  content: "";
  display: block;
  height: 3px;
  width: 0.6rem;
  background-color: #ff5f19;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 0.15rem;
  border-radius: 0.3px;
}
.seleclass2::after {
  content: "";
  display: block;
  height: 3px;
  width: 0.6rem;
  background-color: #ffffff;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 0.15rem;
  border-radius: 0.3px;
}
.zymenu {
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  line-height: 0.45rem;
  color: #9d9d9d;
  margin-top: 0.2rem;
  margin-bottom: 0.38rem;
}
.zymenu > div {
  margin-right: 0.32rem;
  white-space: nowrap;
}
.tipc {
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #6f6f6f;
  margin: 0.6rem 0 0.35rem 0;
}
.inmsg {
  font-size: 0.5rem;
  color: #333333;
  position: relative;
}
/* .clearmsg{
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: .2rem;
} */
.inmsg > .van-cell {
  border: none;
  border-bottom-color: #bfbfbf;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 100%;
  height: 3rem;
  padding-left: 0;
  padding-right: 0;
  overflow: auto;
}
.pjtcla {
  display: flex;
  align-items: center;
  margin: 0.33rem 0.07rem;
}
.pjtcla > div {
  font-size: 0.24rem;
  line-height: 0.3rem;
  color: #ff4900;
  padding: 0.1rem 0.43rem;
  border-radius: 0.39rem;
  background-color: #ffede4;
  margin-right: 0.2rem;
}
.na {
  font-size: 0.42rem;
  line-height: 0.48rem;
  color: #333333;
  font-weight: bold;
}
.pclass {
  margin: 0.47rem 0.48rem;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
  overflow-x: hidden;
}
</style>
